export class CategoryChargebackModel {
	public guid: any;
	public categoryname: string;
	public description: string;
	public rate: string;
	public is_invoicebased: boolean;
	public is_itembased: boolean;
	public is_disputable: boolean;
	public is_recoverable: boolean;
}

export class display_switch {
	edit: boolean;
	view: boolean
	constructor() {
		this.edit = false;
		this.view = true;
	}
}

export class CategoryChargebackStatusModel {
	public guid: any;
	public value: any;
	public label: string;
	public sequence: number;
	public isfinal: boolean;
	public accepted_rejected: string;
	public bgcolor: string;
	public display_switch: display_switch = new display_switch();
}

export class ConfigClassificationModel {
	public guid: any;
	public id: any;
	public name: string;
}

export class AddWorkflowUIModel {
	guid: string;
	title: string;
	workflow_type: string;
	doc_type: string;
	recipients: any[];
	condition: any;
	workflow_status: string;
	comparison_pricelist?: any[];
	is_enable_workflow_execution_order?: boolean;
	pre_workflow?: any[];
	tiers?: any[];
	chargebackBasis?: string;
	constructor() {
		this.recipients = [];
		this.tiers = [];
		this.workflow_status = 'active';
	}
}

export class AddApprovalUIModel {
	guid: string;
	claim_id: string;
	claim_date: string;
	contract_id: string;
	claim_amount: number;
	claim_status: string;
	contracttype: string;
	contract_name: string;
	constructor() {
	}
}

export class AuditLogsUI {
	entity?: string;
	start_date_and_end_date?: any[];
	start_date?: string;
	end_date?: string;
	constructor() {
	}
}

export interface CustomFieldsInterface {
	guid: string;
	feature_guid?: string;
	external_entity_field?: boolean;
	entity_type?: string;
	section?: string;
	custom_field_id?: string;
	ix_custom_field?: string;
	ix_custom_field_title?: string;
	custom_field_title?: string;
	custom_field_type?: string;
	custom_field_output_format?: string;
	is_required?: boolean;
	display?: boolean;
	created_date?: string;
	issynctoerp?: boolean;
	modified_date?: string;
	ixcode?: string;
	contract_type?: string;
	is_contract_field_use_for_creation?: boolean;
	entity_name?: string;
}

export class AddEditCustomFieldsUI implements CustomFieldsInterface {

	public guid: string;
	public feature_guid?: string;
	public external_entity_field?: boolean;
	public entity_type?: string;
	public section?: string;
	public custom_field_id?: string;
	public ix_custom_field?: string;
	public ix_custom_field_title?: string;
	public custom_field_title?: string;
	public custom_field_type?: string;
	public custom_field_output_format?: string;
	public is_required?: boolean;
	public display?: boolean;
	public created_date?: string;
	public issynctoerp?: boolean;
	public modified_date?: string;
	public ixcode?: string;
	public contract_type?: string;
	public is_contract_field_use_for_creation?: boolean;
	public entity_name?: string;
}

export class ApprovalTrailLogsUI {
	approval?: string;
	start_date_and_end_date?: any[];
	start_date?: string;
	end_date?: string;
	approval_status?: string;
	constructor() {
	}
}

export class DistributionChannelsConfigModel {
	guid: string;
	code: string;
	description: string;
}

export class TerritoryConfigModel {
	guid: string;
	territory_name: string;
	classes: Classes[] = [];
	states: State[] = [];
	postalcodes: any[] = [];
	usersLst: any[] = [];
	postalcode_in_territory: string; //include, exclude
	territory_by_address: string; // by billing address, by shipping address
}

export class Classes {
	guid: string;
	name: string;
}

export class State {
	guid: string;
	name: string;
}

export class SalesOrderRuleUIModel {
	guid: string;
	feature_guid: string;
	entity_type: string;
	//type: string; //mapping,fixedvalue
	ix_custom_field: string;
	number: string;
	mapping_values: MappingValueModel[] = [];
}

export class MappingValueModel {
	guid: string;
	number: string;
	value: string;
	key: string;
	match: string;//COMPLETE,PREFIX,SUFFIX
}